import { memo, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import { m } from 'framer-motion'
import { Loader2Icon } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'

import { PermissionCategory } from 'core/remodel/refPaths'
import { AssetType, Currency } from 'core/remodel/types/common'
import { GlobalDashboard } from 'core/remodel/types/globalDashboard'
import { currencyOptions } from 'core/remodel/types/options'
import { defaultPreferences } from 'core/remodel/types/user'
import { fetchCurrentPreferences, userQuery } from '@/api/AccountService'
import { commonQuery, fetchGlobalDashboard } from '@/api/CommonService'
import { fetchPropertySummary, propertyQuery } from '@/api/PropertyService'
import { isMatchedEnv } from '@/constants/site'
import { LocationSummary } from '@/types/common'
import { getEmptyItems } from '@/utils/emptyState'
import { useDisplayedCurrency } from '@/hooks/useDisplayedCurrency'
import useIsMobile from '@/hooks/useIsMobile'
import { useAuthStore } from '@/store/authStore'
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardSummary,
  CardTitle,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '@/components/base'
import { ChartDesc, ComparisonStackedBarChart, DonutChart, TreemapChart } from '@/components/chart'
import { CollectableItem, CollectableItemProps } from '@/components/CollectableItem'
import DataExporter from '@/components/DataExporter'
import { FixedSizeCarousel } from '@/components/FixedSizeCarousel'
import { ChevronDownIcon } from '@/components/icon'
import { MapWidget, MapWidgetCarousel, MapWidgetEmpty, MapWidgetView, PropertyCard } from '@/components/MapWidget'
import Summary from '@/components/Summary'

export default function HomePage() {
  const { t } = useTranslation()
  const { delegatorId, canView } = useAuthStore((state) => state.permissions)
  const database = useAuthStore((state) => state.database)
  const { displayedCurrency, handleDisplayedCurrency } = useDisplayedCurrency()
  const { data: global } = useSWR([commonQuery.global, displayedCurrency], fetchGlobalDashboard(database!), {
    revalidateOnFocus: false
  })

  return (
    <m.main initial={{ opacity: 0 }} animate={{ opacity: 1 }} className={'mx-auto p-4 xl:max-w-screen-xl'}>
      <div className={'flex items-center justify-start md:gap-4'}>
        <h2 className={'mb-3 max-w-fit text-lg text-white md:mb-4'}>{t('GlobalDashboard')}</h2>
        <div className={'ml-auto flex justify-end'}>
          {!global && <Loader2Icon className={'mb-4 flex h-8 w-8 animate-spin text-primary sm:h-12 sm:w-12'} />}
        </div>
        <h2 id={'dashboard-header'} className={'hidden text-grey md:mb-4 md:ml-auto md:block'}>
          {t('CurrencyView')}
        </h2>
        <Autocomplete
          className={'mb-4 min-w-fit md:w-auto'}
          options={currencyOptions}
          value={currencyOptions.find(({ value }) => value === displayedCurrency)}
          onChange={handleDisplayedCurrency}
          placeholder={'Select Currency'}
          isMulti={false}
        />
        {!delegatorId && (
          <DataExporter assetType={'Global'} className={'hidden md:mb-4 md:block md:max-w-full md:pl-1'} />
        )}
      </div>

      <div className={'space-y-5'}>
        <GlobalSummary global={global} />
        <NetValueChart global={global} />
        {canView('MyFinance') && <MyFinancesChart global={global} />}
        {canView(PermissionCategory.Property) && <MyPropertiesChart global={global} />}
        {canView('MyCollectables') && <MyCollectablesChart global={global} />}
        {canView(PermissionCategory.Belonging) && <MyBelongingsChart global={global} />}
      </div>
    </m.main>
  )
}

function GlobalSummary({ global }: { global: GlobalDashboard | undefined }) {
  const { t } = useTranslation()
  const database = useAuthStore((state) => state.database)
  const { data: preferences = defaultPreferences } = useSWR(
    [userQuery.currentPreferences],
    fetchCurrentPreferences(database!)
  )
  const stats = useMemo(() => {
    const { assets, liabilities, netValue } = global ?? {}
    const unit = assets?.currency ?? preferences.baseCurrency ?? Currency.USD
    const value = {
      assets: assets?.value ?? NaN,
      liabilities: liabilities?.value ?? NaN,
      netValue: netValue?.value ?? NaN
    }

    return [
      { label: t('dashboard:MyAssets'), value: value.assets, unit },
      { label: t('dashboard:MyLiabilities'), value: value.liabilities * -1, unit },
      { label: t('dashboard:MyNetWorth'), value: value.netValue, unit }
    ]
  }, [t, preferences, global])

  return <Summary stats={stats} />
}

function NetValueChart({ global }: { global: GlobalDashboard | undefined }) {
  const { t } = useTranslation()
  const database = useAuthStore((state) => state.database)
  const { data: preferences = defaultPreferences } = useSWR(
    [userQuery.currentPreferences],
    fetchCurrentPreferences(database!)
  )
  const isMobile = useIsMobile()
  const [isAssets, setIsAssets] = useState(true)
  const data = useMemo(() => {
    const { valueDistribution } = global ?? {}

    const valueDistributionI18nMap = (label: string) => {
      return t(`common:${label}`)
    }
    const valueDistributionI18n = {
      ...valueDistribution,
      assets: valueDistribution?.assets.map((asset) => ({
        ...asset,
        label: valueDistributionI18nMap(asset.label) ?? asset.label
      })),
      liabilities: valueDistribution?.liabilities.map((liability) => ({
        ...liability,
        label: valueDistributionI18nMap(liability.label) ?? liability.label
      }))
    }
    const chartItems = {
      assets: (valueDistributionI18n?.assets ?? []).map(({ label, value }) => ({ name: label, value: value.value })),
      liabilities: (valueDistributionI18n?.liabilities ?? []).map(({ label, value }) => ({
        name: label,
        value: value.value
      }))
    }

    return chartItems
  }, [global, t])
  const config = useMemo(() => {
    const unit: Currency = global?.assets.currency ?? preferences.baseCurrency!
    const assetsConfig = {
      unit: unit,
      max: Math.max(
        0,
        data.assets.reduce((prev, { value }) => prev + value, 0)
      ),
      hiddenUnassigned: true
    }
    const liabilitiesConfig = {
      unit: unit,
      max: Math.max(
        0,
        data.liabilities.reduce((prev, { value }) => prev + value, 0)
      ),
      hiddenUnassigned: true
    }
    return {
      assets: assetsConfig,
      liabilities: liabilitiesConfig
    }
  }, [data, preferences, global])

  return isMobile ? (
    <Card>
      <CardHeader className={'flex items-center justify-between bg-grey/20 py-4'}>
        <CardTitle className={'text-sm font-medium uppercase'}>{t('NetWorth')}</CardTitle>
        <DropdownMenu>
          <DropdownMenuTrigger asChild={true}>
            <Button className={'gap-1 p-1 text-sm text-text'} variant={'unstyled'}>
              {isAssets ? t('ViewAssets') : t('ViewLiabilities')}
              {<ChevronDownIcon size={16} />}
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className={'bg-white'} align={'end'}>
            <DropdownMenuItem
              className={'text-text focus:bg-primary-hover focus:text-white'}
              onSelect={() => setIsAssets(true)}
            >
              {t('Assets')}
            </DropdownMenuItem>
            <DropdownMenuItem
              className={'text-text focus:bg-primary-hover focus:text-white'}
              onSelect={() => setIsAssets(false)}
            >
              {t('Liabilities')}
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </CardHeader>
      <CardContent>
        <ComparisonStackedBarChart data={data} config={config} isAssets={isAssets} />
      </CardContent>
    </Card>
  ) : (
    <Card>
      <CardHeader className={'bg-grey/20 py-4'}>
        <CardTitle className={'text-sm font-medium uppercase'}>{t('NetWorth')}</CardTitle>
      </CardHeader>
      <CardContent>
        <ComparisonStackedBarChart data={data} config={config} />
      </CardContent>
    </Card>
  )
}

function MyFinancesChart({ global }: { global: GlobalDashboard | undefined }) {
  const { t } = useTranslation()
  const database = useAuthStore((state) => state.database)
  const { data: preferences = defaultPreferences } = useSWR(
    [userQuery.currentPreferences],
    fetchCurrentPreferences(database!)
  )
  const isMobile = useIsMobile()
  const [isAssets, setIsAssets] = useState(true)
  const data = useMemo(() => {
    const { assets, liabilities, netValue, distribution } = global?.myFinances ?? {}

    const financeI18nMap = (label: string) => {
      return t(`finances:${label}`)
    }
    const distributionI18n = {
      ...distribution,
      assets: distribution?.assets.map((asset) => ({
        ...asset,
        label: financeI18nMap(asset.label)
      })),
      liabilities: distribution?.liabilities.map((liability) => ({
        ...liability,
        label: financeI18nMap(liability.label)
      }))
    }

    return {
      summary: {
        assets: assets?.value ?? NaN,
        liabilities: (liabilities?.value ?? NaN) * -1,
        netValue: (assets?.value ?? NaN) + (liabilities?.value ?? NaN) * -1
      },
      assets: (distributionI18n?.assets ?? []).map(({ label, value }) => ({
        name: label,
        value: value.value
      })),
      liabilities: (distributionI18n?.liabilities ?? []).map(({ label, value }) => ({
        name: label,
        value: value.value * -1
      }))
    }
  }, [global, t])
  const config = useMemo(() => {
    return {
      unit: global?.assets.currency ?? preferences.baseCurrency!
    }
  }, [preferences, global])

  return isMobile ? (
    <>
      <Card>
        <div className={'relative'}>
          <CardSummary title={t('dashboard:MyFinances')} data={data.summary} {...config} />
          {/* View assets and liabilities dropdown */}
          <div className={'absolute right-0 top-2'}>
            <DropdownMenu>
              <DropdownMenuTrigger asChild={true}>
                <Button className={'gap-1 p-1 text-sm text-text'} variant={'unstyled'}>
                  {isAssets ? t('ViewAssets') : t('ViewLiabilities')}
                  {<ChevronDownIcon size={16} />}
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className={'bg-white'} align={'end'}>
                <DropdownMenuItem
                  className={'text-text focus:bg-primary-hover focus:text-white'}
                  onSelect={() => setIsAssets(true)}
                >
                  {t('Assets')}
                </DropdownMenuItem>
                <DropdownMenuItem
                  className={'text-text focus:bg-primary-hover focus:text-white'}
                  onSelect={() => setIsAssets(false)}
                >
                  {t('Liabilities')}
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
        <CardContent className={'grid gap-4 lg:grid-cols-2'}>
          {isAssets ? (
            <div className={'grid grid-cols-1 items-center gap-2 sm:grid-cols-2'}>
              <DonutChart data={data.assets} {...config} isAssets={isAssets} />
              <ChartDesc data={data.assets} {...config} isValueInteger={true} />
            </div>
          ) : (
            <div className={'grid grid-cols-1 items-center gap-2 sm:grid-cols-2'}>
              <DonutChart data={data.liabilities} {...config} isAssets={isAssets} />
              <ChartDesc data={data.liabilities} {...config} isValueInteger={true} />
            </div>
          )}
        </CardContent>
      </Card>
    </>
  ) : (
    <Card>
      <CardSummary title={t('dashboard:MyFinances')} data={data.summary} {...config} />
      <CardContent className={'grid gap-4 lg:grid-cols-2'}>
        <div className={'grid grid-cols-1 items-center gap-2 sm:grid-cols-2'}>
          <DonutChart data={data.assets} {...config} />
          <ChartDesc data={data.assets} {...config} isValueInteger={true} />
        </div>
        <div className={'grid grid-cols-1 items-center gap-2 sm:grid-cols-2'}>
          <DonutChart data={data.liabilities} {...config} />
          <ChartDesc data={data.liabilities} {...config} isValueInteger={true} />
        </div>
      </CardContent>
    </Card>
  )
}

const MyPropertiesChart = memo(({ global }: { global: GlobalDashboard | undefined }) => {
  const { t } = useTranslation()
  const database = useAuthStore((state) => state.database)
  const { data: preferences } = useSWR([userQuery.currentPreferences], fetchCurrentPreferences(database!))
  const currency = useMemo(() => global?.assets.currency ?? preferences?.baseCurrency!, [preferences, global])
  const { data: propertySummary } = useSWR(
    currency && [propertyQuery.summary, currency],
    fetchPropertySummary(database!)
  )
  const data = useMemo(() => {
    const { assets, liabilities, netValue, properties = [] } = propertySummary ?? {}
    const locations: LocationSummary[] = properties.map(({ id, location }) => {
      return { item: { id: id, latLng: location.center } }
    })

    return {
      locations,
      summary: {
        assets: assets?.value ?? NaN,
        liabilities: (liabilities?.value ?? NaN) * -1,
        netValue: netValue?.value ?? NaN
      }
    }
  }, [propertySummary])

  const config = useMemo(() => ({ unit: currency }), [currency])

  return (
    <Card>
      <CardSummary title={t('dashboard:MyProperties')} data={data.summary} isAssociatedLiabilities={true} {...config} />
      <CardContent className={'relative overflow-hidden p-0'}>
        <MapWidget>
          <MapWidgetView locations={data.locations} />
          <MapWidgetCarousel locations={data.locations} currency={currency} renderItem={PropertyCard} />
          <MapWidgetEmpty
            open={data.locations.length === 0}
            title={t('EmptyPropertyTitle')}
            subtitle={t('EmptyPropertyMapSub')}
          />
        </MapWidget>
      </CardContent>
    </Card>
  )
})
MyPropertiesChart.displayName = 'MyPropertiesChart'

function MyCollectablesChart({ global }: { global: GlobalDashboard | undefined }) {
  const { t } = useTranslation()
  const database = useAuthStore((state) => state.database)
  const { data: preferences = defaultPreferences } = useSWR(
    [userQuery.currentPreferences],
    fetchCurrentPreferences(database!)
  )
  const currency = useMemo(() => global?.assets.currency ?? preferences.baseCurrency!, [preferences, global])
  const data = useMemo(() => {
    const { assets, liabilities, netValue, subtypeItems = [] } = global?.myCollectables ?? {}
    const reOrderedSubtypeItems = [
      ...subtypeItems.filter((item) => item.assetType === AssetType.Art),
      // Temporarily commented out. TODO: Re-enable when the WineAndSpirits feature is ready for production.
      ...(isMatchedEnv(['prod']) ? [] : subtypeItems.filter((item) => item.assetType === AssetType.WineAndSpirits)),
      ...getEmptyItems({
        assetType: AssetType.OtherCollectables,
        subtypeItems: subtypeItems.filter((item) => item.assetType === AssetType.OtherCollectables),
        length: 4,
        currency,
        mixedEmpty: true
      })
    ]
    const getValue = (targetType: AssetType) =>
      subtypeItems
        .filter(({ assetType }) => assetType === targetType)
        .reduce((prev, { value }) => prev + value.value, 0)
    return {
      summary: {
        assets: assets?.value ?? NaN,
        liabilities: (liabilities?.value ?? NaN) * -1,
        netValue: netValue?.value ?? NaN
      },
      carouselItems: reOrderedSubtypeItems as CollectableItemProps[],
      chartItems: [
        { name: t(`AssetTypeOptions.Art`), value: getValue(AssetType.Art) },
        // Temporarily commented out. TODO: Re-enable when the WineAndSpirits feature is ready for production.
        // { name: t(`AssetTypeOptions.WineAndSpirits`), value: getValue(AssetType.WineAndSpirits) },
        { name: t(`AssetTypeOptions.OtherCollectables`), value: getValue(AssetType.OtherCollectables) }
      ]
    }
  }, [t, global, currency])
  const config = useMemo(() => {
    return {
      unit: currency
    }
  }, [currency])

  return (
    <Card>
      <CardSummary
        title={t('dashboard:MyCollectables')}
        data={data.summary}
        isAssociatedLiabilities={true}
        {...config}
      />
      <CardContent>
        <div className={'grid grid-cols-1 items-center gap-4 lg:grid-cols-4'}>
          <FixedSizeCarousel containerClassName={'lg:col-span-3'} cardWidth={190}>
            {data.carouselItems.map((item, index) => (
              <div key={index} className={'w-full max-w-[190px] shrink-0'}>
                <CollectableItem {...item} />
              </div>
            ))}
          </FixedSizeCarousel>
          <DonutChart data={data.chartItems} {...config} />
        </div>
      </CardContent>
    </Card>
  )
}

function MyBelongingsChart({ global }: { global: GlobalDashboard | undefined }) {
  const { t } = useTranslation()
  const router = useRouter()
  const database = useAuthStore((state) => state.database)
  const { data: preferences = defaultPreferences } = useSWR(
    [userQuery.currentPreferences],
    fetchCurrentPreferences(database!)
  )
  const data = useMemo(() => {
    const { assets, liabilities, netValue, distribution } = global?.myBelongings ?? {}
    return {
      summary: {
        assets: assets?.value ?? NaN,
        liabilities: (liabilities?.value ?? NaN) * -1,
        netValue: netValue?.value ?? NaN
      },
      chartItems: (distribution?.assets ?? []).map(({ label, value }) => ({ name: label, value: value.value }))
    }
  }, [global])
  const currency = useMemo(() => global?.assets.currency ?? preferences.baseCurrency!, [preferences, global])
  const config = useMemo(() => {
    return {
      unit: currency
    }
  }, [currency])

  const i18nParse = (value: string) => t(`collectables:BelongingTypeOptions.${value}`, { defaultValue: value })

  return (
    <Card>
      <CardSummary title={t('dashboard:MyBelongings')} data={data.summary} isAssociatedLiabilities={true} {...config} />
      <CardContent>
        <TreemapChart
          data={data.chartItems}
          onNavigate={(value) => router.push(`/belongings/summary/list/?subType=${value}`)}
          parse={i18nParse}
          {...config}
        />
      </CardContent>
    </Card>
  )
}
